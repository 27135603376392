import './scss/block_2.scss';
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

window.addEventListener("load", function () {
  new Swiper(".mySwiper", {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 3,
    loop: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 2200,
      modifier: 1,
      scale: 2.3,
      slideShadows: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // pagination: {
    //   el: ".swiper-pagination",
    //   clickable: true
    // },
    // breakpoints: {
    //   840: {
    //     slidesPerView: 3
    //   }
    // }
  });
});