// import 'animate.css';
// import Swiper, {Navigation} from 'swiper';
// import 'swiper/css';
// import 'swiper/css/navigation';
import './scss/navigation.scss';

window.addEventListener("load", function(){
	// let audio = document.getElementById("myAudio2");
	// // audio.play();
	// document.querySelector(".btn_music2").onclick = function() {
	// 	if (audio.paused) {
	// 		this.classList.remove("mute");
	// 		audio.play();
	// 	} else {
	// 		audio.pause();
	// 		this.classList.add("mute");
	// 	};
	// };
});