// Scale
window.addEventListener("load", function () {
  Array.from($("#wrapper,.outer, .floating")).forEach(function (item) {
    item.classList.add("scaleDesktop", "scaleMobile");
  });
  $("#wrapper").scalePlatform();
  $(window).on("resize", function () {
    $("#wrapper").scalePlatform();
  });
});

// Target click
var target;
$(".clickActive").on("click", function (e) {
  target = $(this).data("target");
  $(target).toggleClass("active");
  e.preventDefault();
  return false;
});
if ($(".popup .background").length > 0) {
  $(".popup .background").each(function () {
    $(this).on("click", function () {
      $(this).parent().removeClass("active");
    });
  });
}
$("body").on("click", ".close-popup", function (event) {
  //$(".popup").removeClass("active");
  // $(this).parent().parent().removeClass("active");
  $(this).parents('.popup').removeClass("active");
  return false;
});

$('.clickIframe').on("click", function (e) {
  const linkIframe = $(this).data("link");
  const targetIframe = $(this).data("iframe");
  $(targetIframe).attr('src', linkIframe);
  e.preventDefault();
  return false;
});

// document.querySelector('.btn-nolayer').addEventListener("click", function(e) {
//   document.querySelector('#popup_reward').classList.remove("active");
// });


export function observe() {
  return;
  setTimeout(function () {
    $(".scrollwatch-pin").each(function () {
      var elementId = "#" + $(this).attr("id");
      var elParentId = $(this).parents('section').attr("id");

      $().scrollWatch({
        options: {
          watchOnce: false,
          watch: elementId,
          scrollThrottle: 0,
          onElementInView: function () {

            // var theChildren = $(elementId).find(".animate__onview");

            // theChildren.each(function () {
            //   // var elementAnimate = $(this).attr("data-animate");
            //   $(this).addClass("animate__animated animate__backInDown");
            //   // $(this).addClass(elementAnimate);
            // })
          },
          onElementOutOfView: function () {
            // var theChildren = $(elementId).find(".animate__onview ");
            // theChildren.each(function () {
            //   // var elementAnimate = $(this).attr("data-animate");
            //   $(this).removeClass("animate__animated animate__backInDown");
            //   // $(this).removeClass(elementAnimate);
            // });
          }
        }
      });
    });
  }, 300);

  const observeAndRemoveClass = (elementId, classToRemove, elementsToReset) => {
    try {
      const elementToObserve = document.getElementById(elementId);
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (elementToObserve.classList.contains(classToRemove)) {
              const section = elementId;
              $('#' + section).find('.animate__onview').addClass('animate__animated animate__backInDown');
              for (const element of elementsToReset) {
                $(element).removeClass(classToRemove);
                // $(elementsToReset).find('.animate__onview').removeClass('animate__animated animate__backInDown');
                $('.scrollFrameControl').removeClass('active');
                $('.scrollFrameControl[href="#' + elementId + '"]').addClass("active");
              }
            }
          }
        }
      });
      observer.observe(elementToObserve, { attributes: true });
    } catch (error) {
      console.log("Error occurred:");
    }
  };

  const classRemove = 'scroll-watch-in-view';
  observeAndRemoveClass('block_1', classRemove, ['#block_2', '#block_3', '#block_4']);
  observeAndRemoveClass('block_2', classRemove, ['#block_1', '#block_3', '#block_4']);
  observeAndRemoveClass('block_3', classRemove, ['#block_1', '#block_2', '#block_4']);
  observeAndRemoveClass('block_4', classRemove, ['#block_1', '#block_2', '#block_3']);
  // observeAndRemoveClass('block_5', classRemove, ['#block_1', '#block_2', '#block_3']);
  // observeAndRemoveClass('block_6', classRemove, ['#block_1', '#block_2', '#block_3']);
}

// Locate
// var locate = document.querySelector('body').classList[0];
// var url = `./assets/${locate}`;
// // var url = `https://global-mainsite.mto.zing.vn/products/metalslug/landing/2023-muachung/dist/assets/images/locate/${locate}`;
// Array.from(document.querySelectorAll('img')).forEach(function(item) {
//   if (item.dataset.js) {
//     item.src = `${url}/${item.dataset.js}`
//   };
// });